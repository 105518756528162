<template>
  <b-modal
    v-if="!cartEmpty"
    id="checkout-modal"
    ref="checkout-modal"
    centered
    size="lg"
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
  >
    <template #modal-title>
      <div class="cart-title card-title mt-0">
        Checkout

        <div v-if="company" style="display:inline-block">
          |
          <span v-if="company" class="company-name">
            {{ company.name }}
          </span>
        </div>
      </div>
    </template>

    <checkout-cart-body>
      <template #cart-header>
        <h5 class="card-header-text pt-3">
          Order Summary
        </h5>
        <hr>
      </template>
    </checkout-cart-body>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CheckoutModal',
  components: {
    CheckoutCartBody: () => import('@/components/shared/CheckoutCartBody'),
  },
  computed: {
    ...mapGetters('checkout', [
      'cartEmpty',
    ]),
    ...mapGetters('stageline', [
      'company',
    ]),
  },
  methods: {
    close() {
      this.$refs['checkout-modal'].hide()
    },
    show() {
      this.$refs['checkout-modal'].show()
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .modal-body {
  padding: 0 !important;
}

#checkout-modal {
  .company-name {
    margin: 0;
    font-size: .75em;
    color: lightgray;
  }
  .cart-title {
    font-size: 22px;
    font-weight: 700;
    margin: 0;
  }
}
</style>
